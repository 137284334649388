/* eslint-disable no-return-assign */
import React from "react"
import PropTypes from "prop-types"
import * as shortid from "shortid"
import { graphql } from "gatsby"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"

import SEO from "../components/SEO"
import Breadcrumbs from "../components/Breadcrumbs"
import SectionTitle from "../components/SectionTitle"
import MixedCarousel from "../components/carousels/MixedCarousel"
import { Typography } from "@material-ui/core"

const AbbreviationPage = props => {
  const {
    data: { abbreviation, proceduresPages },
    pageContext: { locale, breadcrumbs },
  } = props

  const schema = abbreviation.data.schema_json.text || ""
  const ogp = abbreviation.data.ogp_tags || ""
  const meta = {
    title: abbreviation.data.meta_title,
    description: abbreviation.data.meta_description,
    keywords: abbreviation.data.meta_keywords,
    author: "Medicina Interventionala",
  }

  const proceduresPage = proceduresPages.edges.map(r => r.node).pop()


  return (
    <>
      <SEO
        schema={schema}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
      />
      <main>
        <Container maxWidth="lg">
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <br />
          <Grid
            container
            spacing={4}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12}>
              <SectionTitle component="h1">
                {abbreviation.data.title.text}
              </SectionTitle>
            </Grid>

            <Grid item xs={12}>
              {abbreviation.data.description.text}
            </Grid>
            <Grid item xs={12}>
              {abbreviation.data.body &&
                abbreviation.data.body.map(slice => (
                  <div key={shortid.generate()}>
                    {slice.slice_type === "mixed_carousel" && (
                      <>
                        <Typography
                          component="div"
                          dangerouslySetInnerHTML={{
                            __html: slice.primary.content.html,
                          }}
                        />
                        <MixedCarousel items={slice.items.map(item => item.link.document)} />
                      </>
                    )}
                  </div>
                ))}
            </Grid>
          </Grid>

        </Container>
      </main >
    </>
  )
}

export default AbbreviationPage

export const query = graphql`
  query AbbreviationPageQuery(
    $slug: String!
    $locale: String!
  ) {
    settings: prismicSettings(lang: { eq: $locale }) {
      data {
        site_title
      }
    }

    abbreviation: prismicAbbreviation(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      id
      data {
        title {
          text
        }
        description {
          text
        }
        body {

          ... on PrismicAbbreviationDataBodyMixedCarousel {
            id
            slice_type
            primary {
              content {
                html
                raw
              }
            }
            items {
              link {
                document {
                  ... on PrismicProcedure {
                    id
                    uid
                    type
                    data {
                      categories {
                        category {
                          document {
                            ... on PrismicProcedureCategory {
                              id
                              uid
                              data {
                                icon {
                                  alt
                                  gatsbyImageData(layout: FIXED, width: 150)
                                }
                              }
                            }
                          }
                        }
                      }
                      body1 {
                        ... on PrismicProcedureDataBody1Simple {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                        ... on PrismicProcedureDataBody1RepeatableImageType {
                          id
                          primary {
                            content {
                              text
                            }
                          }
                        }
                      }               
                      title {
                        text
                      }
                    }
                  }


                  ... on PrismicNews {
                    id
                    type
                    uid
                    first_publication_date
                    data {
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                      title {
                        text
                      }
                    }
                  }



                  ... on PrismicCondition {
                    id
                    uid
                    type
                    data {
                      title {
                        text
                      }
                      excerpt {
                        text
                      }
                      image {
                        gatsbyImageData(layout: CONSTRAINED, width: 600)
                      }
                    }
                  }



                  ... on PrismicTeamMember {
                    id
                    lang
                    data {
                      name {
                        text
                      }
                      specialisations {
                        specialisation: specialization {
                          document {
                            ... on PrismicSpecialisation {
                              id
                              uid
                              data {
                                title {
                                  text
                                }
                              }
                            }
                          }
                        }
                      }
                      profile_image {
                        alt
                        gatsbyImageData(layout: FULL_WIDTH, aspectRatio: 1.5)
                        url
                      }
                    }
                    uid
                    type
                  }
                }
              }
            }
          }
        
        
        
        }
        
        schema_json {
          text
        }
        ogp_tags {
          content
          property
        }
        meta_title
        meta_description
        meta_keywords
      }
      lang
    }


    proceduresPages: allPrismicProcedures(filter: { lang: { eq: $locale } }) {
      edges {
        node {
          uid
          lang
          data {
            title
          }
        }
      }
    }
  }
`

AbbreviationPage.propTypes = {
  data: PropTypes.shape({
    settings: PropTypes.object.isRequired,
    abbreviation: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    pageSlug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
}
